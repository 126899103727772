@import "../../../nsw-frontend-library/styles/variables.scss";

.guestLayout {
  background-image: url("../../../assets/images/auth-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;

  .loginLogo {
    display: flex;
    justify-content: center;

    > .logowrapper {

      margin-left: -30px;
      margin-right: -30px;

      img {
        width: 100%;
        height: auto;
      }

      //@media screen and (max-width: 576px) {
      //  width: 200px !important;
      //  height: 33px !important;
      //  min-width: 200px !important;
      //  min-height: 33px !important;
      //}
      //height: auto;
      width: 100% !important;
      max-width: 350px !important;

      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      margin-bottom: 40px;
    }
  }

  .cardWrapper {
    width: 100%;
    height: 100%;

    .guestCard {
      width: 90% !important;
      margin-left: auto;
      margin-right: auto;
      border-radius: 1em;
      margin-top: 50px;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      color: white;
      padding: 50px 100px;
      transition: .4s;

      &:hover {
        background: rgba(0, 0, 0, 0.64);
        transition: .4s;

      }
    }

    @media screen and (max-width: 576px) {
      .guestCard {
        padding: 50px;
      }
    }
  }
}
