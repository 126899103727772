.login {
  display: flex;
  flex-direction: column;

  .checkboxlogin {
    color: #eb9123;
  }

  .loginLinks {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
