@import "src/nsw-frontend-library/styles/variables";

.lightCard-input-field {
  width: 100%;
  font-size: 18px;
  background-color: white;
  border-radius: 8px;
  margin: 2px;
  height: 2.2em;
  padding-left: 10px;
  border: 1px solid lightgrey;
  &:focus {
    outline: none;
    border-radius: 8px;
    border:2px solid orange;
  }
}

.darkCard {
  background-color: $darkCard-background;
  border-radius: 8px;
  color: white;
  box-shadow: $box-shadow;
  border: 1px solid #1c1c1c;
  font-size: 24px;
  padding: 10px;
  .darkCard-title {
    font-weight: bold;
    margin: 0 0 10px 10px;
  }
  .darkCard-button{
    border-radius: 8px;
    background-color: white;
    color:black;
    font-size: 18px;
    font-weight: bold;
    padding: 8px;
  }
}

.lightCard {
  box-shadow: $box-shadow;
  border: 1px solid #f0f0f0;
  background-color: white;
  border-radius: 8px;
  font-size: 24px;
  padding: 10px;
  .lightCard-title {
    font-weight: bold;
    margin: 0 0 10px 10px;
  }
  .lightCard-inputLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    padding: 7px;
  }
  .ant-select{
    width:100%;
    font-size: 18px;
  }
  .react-datepicker__input-container input {
    width: 100%;
    box-shadow: white;
  }

  .lightCard-inputTextBox {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    border-radius: 8px;
    margin: 2px;
  }
  .lightCard-divider {
    width: 100%;
    height: 2px;
    background-color: $text-lightGray;
    opacity: 0.5;
  }
  .lightCard-verticalDivider{
    width: 2px;
    background-color: $text-lightGray;
    opacity: 0.7;
    margin-left: 10px;
    margin-right: 10px;
  }
  .lightCard-button{
    border-radius: 8px;
    background-color: #ff8c00;
    color:white;
    font-size: 18px;
    font-weight: bold;
    padding: 8px;
    cursor: pointer;
  }
  .lightCard-button-deactivated{
    border-radius: 8px;
    background-color: darkgray;
    color:white;
    font-size: 18px;
    font-weight: bold;
    padding: 8px;
  }
  .lightCard-filter{
    .lightCard-filter-text{
      margin-right: 5px;
    }
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.modalCard{
  box-shadow: $box-shadow;
  border: 1px solid #f0f0f0;
  background-color: white;
  border-radius: 8px;
  font-size: 24px;
  padding: 10px;
  font-weight: bold;
  .modalCard-buttonAlignment{
    text-align: right;
  }
  .modalCard-primaryButton{
    border-radius: 8px;
    background-color: orange;
    color:black;
    font-weight: bold;
    font-size: 28px;
    border-width: 3px;
    border-color: black;
    margin: 5px;
  }
  .modalCard-secondaryButton{
    border-radius: 8px;
    background-color: white;
    color:black;
    font-weight: bold;
    font-size: 28px;
    border-width: 3px;
    border-color: black;
    margin: 5px;
  }
}

.cardMargin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.card100PercentHeight {
  height: 100%;
}

.card100PercentWidth {
  width: 100%;
}