.feedback {
  padding: 20px 15px;
  margin-bottom: 20px;

  .sec2 {
    margin-top: 10px;
  }

  > button {
    margin-top: 10px;
  }
}

.searchHighlight{
  background-color: yellow;
}

.faqCard {
  display: flex;
  padding: 10px;
  font-size: 16px;
  .faqButtonCard{
    .faqButton {
      justify-content: center;
    }
  }
  .faqQuestion{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .faqAnswer {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    text-align: justify;
    white-space: pre-wrap;
  }
}

.questionCard {
  background-color: white;
  border-radius: 8px;
  height: 100%;
  vertical-align: bottom;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f0f0f0;
}

.faqHeader {
  margin-top: 15px;
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}

.faqSearch {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  width: 200px;
  margin-bottom: 15px;
}

.faqLoadMoreButton {
  horiz-align: center;
}

.faqLoadMoreButtonCard {
  horiz-align: center;
}
