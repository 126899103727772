.profile-picture-uploader-div{
  align-content: center;
  text-align: center;

  .profile-picture-uploader-image{
    width: auto;
    height: 200px;
  }

  .profile-picture-uploader-label {
    margin-top: 5px;
    color: orangered;

    > label {
      cursor: pointer;
    }

    > input {
      visibility: hidden;
      display: none;
    }
  }
}