.fileUploader {
  width: 30%;

  .uploader {
    margin: 10px;
    padding: 10px;

    .uploadTitle {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
