@import "variables";

.overflowHidden {
  overflow: hidden;
  text-overflow: ellipsis;
}

.check {
  color: green;
}

.close {
  color: red;
}

.display-linebreak {
  white-space: pre-line;
}

.margin-bot10 {
  margin-bottom: 10px;
}

.margin-bot18 {
  margin-bottom: 18px;
}

.margin-top18 {
  margin-top: 18px;
}

.padding-10{
  padding: 10px;
}

.bold {
  font-weight: bold;
}

.ant-btn-primary {
  background: $text-goldenrod;
  border-color: $text-goldenrod;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:focus,
.ant-btn-primary:visited {
  color: #fff !important;
  background: #ffbf21;
  border-color: #ffbf21;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  color: $text-goldenrod;
  color: $text-goldenrod;
  border-color: $text-goldenrod;
}

.ant-row{
  margin-top: 16px;
  margin-bottom: 16px;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}
