@import "../../nsw-frontend-library/styles/variables.scss";

.menu::-webkit-scrollbar {
  display: none;
}

.menu {
  margin-block-end: 0 !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
  overflow-y: auto;
  border: unset;
  //margin-bottom: 40px;
  @media only screen and (min-width: 600px) {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.15);
    .ant-menu-submenu-arrow {
      color: white;
    }
    ul {
      color: white;
    }
  }

  .logoImage {
    width: 200px;
    padding: 11px 12px 13px 15px;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.5);
    border-radius: 0px 8px 8px 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    background: white;
    position: fixed;
    @media only screen and (max-width: 600px) {
      width: 190px;
    }
  }
  .profile-menu {
    margin-top: 90px;
    text-align: center;
    .profileImage {
      width: auto;
      border-radius: 50%;
      height: 150px;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .profile-info{
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 16px;
    }
    .profile-divider {
      margin-top: 10px;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-size:16px;
  background-color: rgba(255, 255, 255, 0.5);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
  font-size:16px;
}

@media only screen and (min-width: 600px) {
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid white;
  }
  .ant-menu-item-selected {
    color: black !important;
  }
  .ant-menu-item-active {
    color: black !important;
  }
}
