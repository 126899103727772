@import "../../nsw-frontend-library/styles/variables.scss";

.editProfile {
  .saveSettingsCard {
    width: 190px;
  }

  .editProfileLightCard {
    height: 100%;
    width: 100%;

    .shareWithUplineDiv {
      margin-left: 5px;
      display: flex;

      .shareWithUplineCheckbox {
        padding: 8px;
      }

      .ant-checkbox .ant-checkbox-inner {
        border-color: black;
      }
    }
  }
}
