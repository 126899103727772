.inputStyle {
  border-radius: 8px !important;

  > .ant-select-selector {
    border-radius: 6px !important;
  }
}

.transparentInput {
  padding: 15px 10px;
  height: auto;
  line-height: normal;
  background: transparent;
  border: 1px solid #d3d3d3;
  color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;

  &:focus {
    outline: unset;
  }
}

.ivpFormField {
  font-family: Helvetica, arial, sans-serif;
  height: 100% !important;
  width: 100%;
  background: lightgrey;
  color: black;
  text-align: left;
  border-radius: 10px;
  transition: .2s;



  &:focus {
    height: 100% !important;
    background-color: #ffffff;
    transition: .2s;
  }
}

.ivpFormButton {
  background-color: #eb9123;
  border-radius: 10px;
  border: none !important;

  &:hover {
    background-color: orange;
    color: white;
    font-weight: bold;
    transition: .2s;

  }
}

.orangeButton {
  height: 50px;
  width: 100%;
  background-color: #eb9123;
  border: 1px solid #eb9123;
  color: white;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: orange;
  }

  &:focus {
    outline: unset;
  }
}
