@import "../../nsw-frontend-library/styles/variables.scss";

.commission{
  
  .commission-darkCard {
    height: 100%;
    text-align: center;

    .commission-darkCard-price {
      display: flex;
      align-items: center;
      justify-content: center;

      .commission-darkCard-digit {
        font-size: 26px;
        font-weight: bold;
      }

      .commission-darkCard-pdfIcon {
        color: $text-goldenrod;
        font-size: 25px;
        margin-left: 22px;
      }
    }

    .commission-darkCard-text {
      font-size: 18px;
    }
  }
}
