@import "../../styles/variables";

.loading {
  display: flex;
  justify-content: center;
  margin-top: 200px;

  .loading-icon {
    width: 15%;
    height: 15%;
    color: $text-lightGray;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
