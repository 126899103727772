$register-mainThemeColor: #eb9123;
$register-componentFontSizeHeader: 24px;
$register-componentFontSize: 18px;
$register-componentBorderRadius: 10px;
$register-componentBorder: none;
$register-componentHeight: 50px;

.register-field {
  background-color: #e2e2e2;
  border: $register-componentBorder;
  border-radius: $register-componentBorderRadius;
  color: black;
  font-size: $register-componentFontSize;
  height: $register-componentHeight;
  padding-left: 10px;
  text-align: left;
  width: 100%;

  &:focus {
    background-color: white;
  }
}

.register-selection {

  .ant-select {
    font-size: $register-componentFontSize;
    width: 100%;

    .ant-select-selector {
      background-color: #e2e2e2;
      border-radius: $register-componentBorderRadius;
      height: $register-componentHeight;
      padding-top: 8px;
      padding-left: 10px;
    }

    .ant-select-selection-placeholder {
      font-size: $register-componentFontSize;
      color: #656565;
    }
  }
}

.register-checkbox-div {
  margin-bottom: 8px;

  .ant-checkbox-wrapper {
    font-size: $register-componentFontSize;
    color: $register-mainThemeColor;
  }
}

.register-header-label {
  color: $register-mainThemeColor;
  font-size: 24px;
  font-weight: bold;
  margin: 5px;
}

.register-button {
  background-color: $register-mainThemeColor;
  border-radius: $register-componentBorderRadius;
  border: $register-componentBorder;
  color: white;
  cursor: pointer;
  height: $register-componentHeight;
  font-size: $register-componentFontSize;
  font-weight: bolder;
  padding: 8px;
  margin: 10px 5px 5px;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: $register-mainThemeColor;
  }
}