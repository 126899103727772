@import "../../nsw-frontend-library/styles/variables.scss";

.header {

  .title-sec {
    display: flex;
    align-items: center;

    .title-icon {
      color: #fff;
      font-size: 36px;
      margin-left: 5px;
      margin-right: 15px;

      &:hover {
        color: $text-hover-blue;
      }
    }

    .title-text {
      color: white;
      font-size: 34px;
      font-weight: bold;
    }
  }

  .column{
    justify-content: flex-end;
    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  .headerLightCard{
    display: flex;
    justify-content: center;
    height: 100%;

    .directIndirectPartner{
      display: flex;

      .checkNumberDiv {
        display: flex;
        margin-left: 5px;

        > div:nth-child(2) {
          margin: 4px 4px 0 4px;
        }
      }
    }
  }

  .proAmountCard{
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .exit-sec {
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
.headerButton {
  display: inline-block;
  width: 50%;
  background: transparent;
  color: white;
  border: none;
  box-shadow: none;
  height: 60px;
}
}






