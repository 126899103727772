@import "../../nsw-frontend-library/styles/variables.scss";

.share{
  .share-link{
    margin-top: 4.5%;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    @media screen and (max-width: 1200px) {
      font-size: 30px;
    }
    @media screen and (max-width: 940px) {
      font-size: 20px
    }
  }
  .share-register{
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5%;
    border-radius: 10px;
    margin-top: 16px;
    max-width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .share-register-header{
      color: white;
      font-size: 32px;
    }
  }
}
