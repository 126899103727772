.selectLanguageContainer {
  //position: fixed;
  //top: 2%;
  //right: 2%;
}

.selectLanguage {
  color: black;
}
.lngSelectImage {
  cursor: pointer;
}
