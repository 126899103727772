@import "../../nsw-frontend-library/styles/variables.scss";

.invoices {

  .invoices-pdf-icon {
    color: $text-goldenrod;
    font-size: 25px;
  }
  .invoices-filter{
    width: max-content;
  }
}


