@import "variables";

.lightTable {
  overflow: auto;
  border-radius: 8px;
  box-shadow: $box-shadow;
  background-color: $darkCard-background;
  white-space: nowrap;

  tr {
    background-color: white;
  }

  thead[class*="ant-table-thead"] th {
    color: white;
    background-color: $darkCard-background;
    font-size: 20px;
  }

  ul[class*="ant-table-pagination"] {
    color: white;
    background-color: $darkCard-background;
    margin-right: 20px;
    font-size: 18px;
  }

  tr[class*="ant-table-row"] {
    font-size: 18px;
    padding: 5px;
  }

  div[class*="ant-table"]{
    line-height: 15px;
  }
}