@import "../../nsw-frontend-library/styles/variables.scss";

.shop {
  margin-top: 35px;

  .basicCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;

    .basicCard-text {
      margin-top: 30px;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .ProCard {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;

    .procard-recom {
      background-color: $text-goldenrod;
      border-color: $text-goldenrod;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      color: white;
      position: absolute;
      border-radius: 8px;
      font-weight: bold;
      top: -20px;
      right: -3px;
      padding: 3px 10px;
    }

    .proListTitle {
      font-weight: bold;
      margin-top: 10px;
    }

    .ProLists {
      .buySec {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 20px;

        .buySec-select {
          margin: 15px 8px 15px 0;
        }

        .buySec-button {
          width: 100px;
        }
      }
    }
  }

  .shopBut {
    margin: 20px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: center;
    width: 102px;
  }

  .shopButSVG {
    margin-left: 4px;
    font-size: 16px;
  }
}

.shopCardTitle {
  > div:nth-child(1) {
    font-size: 16px;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    > div:nth-child(1) {
      font-weight: bold;
      font-size: 16px;
    }

    > div:nth-child(2) {
      font-size: 12px;
    }
  }
}

.shopCardList {
  padding: unset;
  margin: unset;
  list-style-type: none;

  > li {
    display: flex;
    margin-bottom: 3px;

    > div {
      margin-left: 8px;
      width: 90%;
    }
  }
}

.closeIcon {
  background-color: #eb4738;
  color: white;
  padding: 3px;
  font-size: 20px;
  border-radius: 4px;
}

.checkIcon {
  background-color: #1890ff;
  color: white;
  padding: 3px;
  font-size: 20px;
  border-radius: 4px;
}

