@import "../../nsw-frontend-library/styles/variables.scss";

.shop {
  .productCard{
    height: 100%;
  }
  .featureListHeader {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
  }
  .featureItemDiv{
    display: flex;
    padding: 4px;
    .featureItemName{
      margin-left: 5px;
    }
  }
  .shopBasicTitle{
    margin-top:15px;
  }
  .shopSessionImage {
    margin-top: 20px;
    width: 100%;
    max-width: 1000px;
  }
  .shopPaymentMethodSelection{
    width: 280px;
  }
  .shopBuyButton{
    width: 280px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shopProductSpecialOffer{
    margin-top: 20px;
    font-size: 13px;
    //width: 50%;
    float: right;
    font-weight: bold;
  }
  .shopPriceVatReferenceText{
    font-size: 14px;
    font-weight: normal;
    vertical-align: top;
    margin-left: 5px;
  }
}


