.emailCheck {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 2%;
  color: #eb9123;

  .buttonWithLabel {
    display: flex;
    flex-direction: column;
  }
}
