@import "../../nsw-frontend-library/styles/variables.scss";

.dashboard {
  .checkNumberDiv {
    display: flex;

    > div:nth-child(2) {
      //margin: 2px 0 0 8px;

      svg {
        font-size: 100%;
        vertical-align: sub;
        margin-left: 10px;
      }
    }
  }

  .dashboard-darkCard {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .dashboard-darkCard-item {
      margin-top: 10px;
      font-size: 40px;
      margin-right: 15px;
    }

    .dashboard-darkCard-digit {
      font-weight: bold;
      font-size: 40px;
      margin-right: 15px;
    }

    .dashboard-darkCard-text {
      font-size: 18px;
    }
  }

  .dashboard-lightCard {
    height: 100%;
    width: 100%;
    .lightCard-content {
      margin-top: 20px;
      font-size: 20px;
      .list-items {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .dashboard-slider-item {
    > img {
      width: 100%;
      border-radius: 8px;
      margin: 5px;
      padding: 5px;
    }
  }
}
