@import "../../nsw-frontend-library/styles/variables.scss";

.mediaCenter {
  .mediaCenterCard{
    height: 100%
  }
  .mainContent{
    font-size: 18px;
    padding: 10px;
  }
  .mediacenter-pdf-item {
    display: flex;
    padding: 5px;
    font-size: 22px;
    font-weight: bold;
  }
}
