.registration {
  .register-video {
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 1em;
    margin-left: -30px;
    margin-right: -30px;
  }
}

