.input-validation-field-invalid {
  border-color: red;
  border-width: 2px;
}

.input-validation-field-label-valid {
  display: none;
  margin-top: 0;
}

.input-validation-field-label-invalid {
  display: block;
  color: lightcoral;
  font-size: 18px;
}

.input-validation-checkbox-valid {
  .ant-checkbox-inner {
    border-color: white;
    border-width: 0px;

    &:hover {
      border-color: white;
      border-width: 0px;
    }
  }
}

.input-validation-checkbox-invalid {

  .ant-checkbox .ant-checkbox-inner {
    border-color: red;
    border-width: 2px;

    &:hover {
      border-color: red;
      border-width: 2px;
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: red;
    border-width: 2px;

    &:hover {
      border-color: red;
      border-width: 2px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: red;
    border-width: 2px;

    &:hover {
      border-color: red;
      border-width: 2px;
    }
  }
}