
.rd3t-svg{
  background: white;
}

.featureNotAvailable {
  background: white;
  font-size: 15px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 5%;
  padding: 5%;
  border-radius: 10px;
  width: 100%;
}

.teamtree {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 80%;

  .treeWrapper {
    width: 100%;
    height: 100%;
  }

  .filterSec {
    cursor: pointer;
    display: flex;
    margin-bottom: 6px;

    .ant-select-selector {
      border-radius: 6px !important;
    }

    .levelFilter {
      margin-right: 10px;
    }

    .usersFilter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      background-color: white;
      border-radius: 6px;

      > span {
        margin: 2px 0 0 5px;
      }
    }
  }
}

.nodeWrapper {
  overflow: inherit;
  top: -45px;
  right: 50px;
  > div {
    div {
      top: -45px;
      right: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
    }
  }
}

.arrowProfessional {
  color: goldenrod;
  margin: 5px 0;
  font-size: 18px;
}

.arrowBasic {
  color: gray;
  margin: 5px 0;
  font-size: 18px;
}

.nodeNameProfessional {
  background-color: goldenrod;
  font-size: 12px;
  padding: 2px 3px 4px 3px;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #fff;
  width: 75%;
  text-align: center;
}

.nodeNameBasic {
  background-color: gray;
  font-size: 12px;
  padding: 2px 3px 4px 3px;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #fff;
  width: 75%;
  text-align: center;
}

.nodeImage {
  width: 74px;
  height: 74px;
  border: 2px solid #c8d5d8;
  border-radius: 50%;
  padding: 4px;
  background: #fff;
  cursor: pointer;
  object-fit: cover;
}

.tooltip {
  width: 240px;

  .tooltipContent {
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 10px;

    .basicPackage {
      background-color: gray;
      color: white;
      padding: 2px;
      border-radius: 2px;
      margin: 6px auto;
      width: 48%;
      text-align: center;
    }

    .professionalPackage {
      background-color: goldenrod;
      color: white;
      padding: 2px;
      border-radius: 2px;
      margin: 6px auto;
      width: 48%;
      text-align: center;
    }

    > ul {
      list-style-type: unset;
      padding: unset;
      margin: unset;

      > li {
        text-align: left;
        display: flex;
        justify-content: space-between;

        > div:nth-child(1) {
          margin-right: 6px;
        }

        > div {
          flex: 1;
        }
      }
    }
  }
}

.ant-tooltip-inner {
  padding: unset !important;
}

.custom-link {
  stroke: #c8d5d8 !important;
}
