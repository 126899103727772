@import "../../nsw-frontend-library/styles/variables.scss";

.support {
  .supportCard {
    padding: 20px;
    height: 100%;

    .supportCard-title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      > div {
        margin-left: 10px;
        font-weight: bold;
        color: $text-goldenrod;
      }

      > svg {
        background-color: $text-goldenrod;
        color: white;
        font-size: 30px;
        border-radius: 50%;
        padding: 5px;
      }
    }

    .supportCard-sec {
      display: flex;
      padding: 0 10px;

      > svg {
        margin-right: 15px;
      }
    }

    .supportCard-text {
      padding: 0 10px;
      font-size: 13px;
    }

    .bonusText {
      margin-bottom: 10px;
    }
  }
}
