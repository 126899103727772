@import "../../nsw-frontend-library/styles/variables.scss";
@import "../../style.scss";

.home-darkCard {
  color: white;
  height: 6em;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;

  > svg {
    font-size: 30px;
    margin-right: 15px;
  }

  .darkCard-content {
    display: flex;
    flex-direction: column;

    .content-digit {
      font-size: 20px;
      font-weight: bold;
    }

    .content-text {
      font-size: 10px;
    }
  }
}

.home-lightCard {
  .lightCard-header {
    padding: 12px;
    display: flex;
    align-items: center;

    > svg {
      font-size: 23px;
      margin-right: 10px;
      color: $text-goldenrod;
    }

    > div {
      font-weight: bold;
      color: black;
      font-size: 12px;
    }
  }

  .lightCard-divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
  }

  .lightCard-content {
    color: $text-dark;
    padding: 12px 16px 0 16px;

    .lightCard-item {
      padding: unset;
      margin: unset;
      display: flex;
      list-style-type: none;

      > li {
        padding-bottom: 12px;
      }

      > li:nth-child(1) {
        width: 30px;
      }

      > li:nth-child(2) {
        flex: 1;
      }
    }
  }
}
